@import '../resources/vars';
@import '../resources/mixins';

.container {
  display: flex;
  flex-direction: var(--app-custom-informer-direction, var( --forms-informer-direction, row));
  background: var(--app-custom-informer-background, var(--forms-informer-background));
  padding: var(--app-custom-informer-padding, var(--forms-informer-padding));
  gap: var(--app-custom-informer-gap, var(--forms-informer-gap));
  justify-content: var(--app-custom-informer-justify-content, var(--forms-informer-justify-content));
  align-items: var(--app-custom-informer-align-items, var(--forms-informer-align-items,  flex-start));
  border: var(--app-custom-informer-border, var(--forms-informer-border));
  border-radius: var(--app-custom-informer-border-radius, var(--forms-informer-border-radius));
  box-shadow: var(--app-custom-informer-box-shadow, var(--forms-informer-box-shadow));
  width: var(--app-custom-informer-width, var(--forms-informer-width));
  max-width: var(--app-custom-informer-max-width, var(--forms-informer-max-width));
  margin: var(--app-custom-informer-margin, var(--forms-informer-margin));

  @include respond-to(sm, up) {
    width: var(--app-custom-informer-width-m, var(--forms-informer-width-m));
    padding: var(--app-custom-informer-padding-m, var(--forms-informer-padding-m));
    gap: var(--app-custom-informer-gap-m, var(--forms-informer-gap-m));
  }

  @include respond-to(md, up) {
    width: var(--app-custom-informer-width-l, var(--forms-informer-width-l));
    padding: var(--app-custom-informer-padding-l, var(--forms-informer-padding-l));
    gap: var(--app-custom-informer-gap-l, var(--forms-informer-gap-l));
  }
}

.icon {
  flex-grow: 0;
  background: var(--app-custom-informer-icon-background, var(--forms-informer-icon-background));
  padding: var(--app-custom-informer-icon-padding, var(--forms-informer-icon-padding));

  @include respond-to(sm, up) {
    padding: var(--app-custom-informer-icon-padding-m, var(--forms-informer-icon-padding-m));
  }

  @include respond-to(md, up) {
    padding: var(--app-custom-informer-icon-padding-l, var(--forms-informer-icon-padding-l));
  }

  svg {
    width: var(--app-custom-informer-svg-size, var(--forms-informer-icon-size));
    height: var(--app-custom-informer-svg-size, var(--forms-informer-icon-size));
    border: var(--app-custom-informer-svg-border, var(--forms-informer-icon-border));
    border-radius: var(--app-custom-informer-svg-border-radius, var(--forms-informer-icon-border-radius));
  }

  path {
    fill: var(--app-custom-informer-svg-color, var(--forms-informer-icon-color));
  }
}

.text {
  p {
    @include with-responsive-styled-text(app-custom-informer-p);
    @include rewrite-responsive-styled-text-vars(app-custom-informer-p, forms-informer-p);
  }

  ul {
    li {
      @include with-responsive-styled-text(app-custom-informer-li);
      @include rewrite-responsive-styled-text-vars(app-custom-informer-li, forms-informer-li);
    }
  }
}

.interactive {
  @include informer-style-override(forms-informer-int)
}
