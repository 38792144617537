@import '../resources/vars';
@import '../resources/mixins';

.container {
  margin-bottom: var(--coach-spacer-group);
}

.header {
  display: var(--theme-coach-avatar-display);
  margin-bottom: var(--coach-spacer-avatar);
  padding: var(--coach-avatar-padding);
}

.avatar {
  align-items: var(--coach-avatar-align-items);
  display: flex;
  justify-content: var(--coach-avatar-justify-content);
}

.image {
  border-radius: var(--coach-avatar-border-radius);
  height: var(--coach-avatar-height);
  overflow: hidden;
  width: var(--coach-avatar-width);
}

.body {
  margin-right: var(--coach-message-margin-right);
}

%message {
  background-color: var(--coach-message-bg-colour);
  border: var(--coach-message-border);
  border-radius: var(--coach-message-border-radius);
  box-shadow: var(--coach-message-box-shadow);
  color: var(--coach-message-text-colour);
  font-family: var(--coach-message-font-family);
  font-size: var(--coach-message-text-size);
  font-style: var(--coach-message-text-style);
  font-weight: var(--coach-message-text-weight);
  letter-spacing: var(--coach-message-letter-spacing);
  line-height: var(--coach-message-line-height);
  padding: var(--coach-message-padding);
  text-align: var(--coach-message-text-align);
  text-transform: var(--coach-message-text-transform);
  word-break: var(--coach-message-word-break);
  max-width: var(--coach-message-max-width);

  @include respond-to(sm, up) {
    font-size: var(--coach-message-text-size-m, var(--coach-message-text-size));
    max-width: var(--coach-message-max-width-m, var(--coach-message-max-width));
  }

  @include respond-to(lg, up) {
    font-size: var(--coach-message-text-size-l, var(--coach-message-text-size));
    max-width: var(--coach-message-max-width-l, var(--coach-message-max-width));
  }
}

.bubble {
  @extend %message;
}

.bubbles {
  display: flex;
  flex-direction: column;
  gap: var(--coach-spacer-par);
  max-width: var(--coach-messages-max-width);

  @include respond-to(sm, up) {
    max-width: var(--coach-messages-max-width-m, var(--coach-messages-max-width));
  }

  @include respond-to(lg, up) {
    max-width: var(--coach-messages-max-width-l, var(--coach-messages-max-width));
  }

  &.singular {
    @extend %message;
  }

  &.plural {
    > p,
    > span {
      @extend %message;
    }
  }
}
