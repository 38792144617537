@import '../resources/vars';
@import '../resources/mixins';

.container,
.menu,
.items {
  width: 100%;
}

.container {
  position: relative;

  order: var(--forms-input-text-order-field);
}

.menu {
  overflow: hidden;
  background-color: var(--popover-menu-background-color);
  border-color: var(--popover-menu-border-color);
  border-radius: var(--popover-menu-border-radius);
  border-style: var(--popover-menu-border-style);
  border-width: var(--popover-menu-border-width);
  box-shadow: var(--popover-menu-box-shadow);
  min-height: var(--popover-menu-item-height);
  padding: var(--popover-menu-padding);
  z-index: var(--app-z-index-layer-100);

  &[data-loading='true'] {
    display: flex;
  }
}

.items {
  max-height: var(--popover-menu-max-height);
}

.item {
  align-items: center;
  cursor: pointer;
  display: flex;
}

.item {
  background-color: var(--popover-menu-item-background-color);
  border-color: var(--popover-menu-item-border-color);
  border-radius: var(--popover-menu-item-border-radius);
  border-style: var(--popover-menu-item-border-style);
  border-width: var(--popover-menu-item-border-width);
  box-shadow: var(--popover-menu-item-box-shadow);
  min-height: var(--popover-menu-item-height);
  padding: var(--popover-menu-item-padding);

  &[disabled] {
    opacity: var(--pattern-disabled-opacity);
  }

  &[data-focused='true'] {
    background-color: var(--popover-menu-item-background-color-hover);

    .value {
      color: var(--popover-menu-item-label-color-hover);
    }
  }
}

.focused {
  background-color: var(--popover-menu-item-background-color-focus);

  .value {
    color: var(--popover-menu-item-label-color-hover);
  }
}

.value {
  overflow: var(--popover-menu-item-label-overflow, hidden);
  text-overflow: var(--popover-menu-item-label-text-overflow, ellipsis);
  white-space: var(--popover-menu-item-label-white-space, nowrap);

  @include with-styled-text(popover-menu-item-label);
  flex: 1;
  margin: var(--popover-menu-item-label-margin);
}

.icon {
  margin: var(--popover-menu-item-icon-margin);

  svg {
    height: var(--popover-menu-item-icon-size);
    width: var(--popover-menu-item-icon-size);
  }

  path {
    fill: var(--popover-menu-item-icon-colour);
  }
}

.spinner {
  svg {
    height: var(--input-spinner-size);
    width: var(--input-spinner-size);
  }

  path {
    fill: var(--input-spinner-colour);
  }
}
